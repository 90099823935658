<template>
    <div class="hero-filters">
        <div class="row mb-3 mb-md-5">
            <div class="col-12 col-md-9 mb-3">
                <button v-if="showResult" @click="showResult = false" type="button" class="btn">
                    <img src="/img/arrow-left.svg" class="align-text-bottom" alt="«">
                    &nbsp;
                    <span class="h4">Наше предложение</span>
                </button>
                <h4 v-else>
                    Выбрать автомобиль с пробегом
                    <span v-if="region.nominative">
                        в
                        <button type="button" class="btn fz-inherit text-primary btn-link p-0 fw-bold align-baseline text-decoration-none" onclick="window.toggleSubdomainDropdown()">
                            {{ region.prepositional }}
                            &nbsp;
                            <img src="/img/right-sm.svg" alt="&raquo;">
                        </button>
                    </span>
                </h4>
            </div>
            <div class="col-12 col-md-3 text-end mb-3">
                <toggler :mode="buysell" @toggle="buysell = $event; showResult = false; forgetVIN = false" />
            </div>
        </div>
    </div>

    <buy-screen v-if="!showResult && buysell == 'buy'" />
    <sell-screen v-else-if="!showResult && buysell == 'sell' && !forgetVIN" @submit="showSellOffer($event)" @forget="forgetVIN = true" :loading="sellFormLoading" />
    <sell-offer-screen v-else-if="showResult && buysell == 'sell'" @cancel="showResult = false" :calculation="sellCalculation" />
    <sell-screen-full v-else-if="!showResult && buysell == 'sell' && forgetVIN" @submit="showSellOffer($event)" @cancel="forgetVIN = false" :loading="sellFormLoading" />
</template>

<script>
import axios from 'axios';

import Toggler from './toggler';
import BuyScreen from './buy-screen';
import SellScreen from './sell-screen';
import SellOfferScreen from './sell-offer-screen';
import SellScreenFull from './sell-screen-full';

export default {
    props: {
        toggler: {
            type: String,
            default: 'buy',
            validator(value) {
                return ['buy', 'sell'].includes(value);
            }
        }
    },
    data() {
        return {
            buysell: this.toggler,
            showResult: false,
            forgetVIN: false,
            sellCalculation: {},
            sellFormLoading: false,
        }
    },
    methods: {
        sellRequest() {
            return
        },
        showSellOffer(form) {
            this.sellFormLoading = true;

            // Serialize
            let queryString = [];
            for (var p in form)
            if (form.hasOwnProperty(p)) {
                queryString.push(encodeURIComponent(p) + "=" + encodeURIComponent(form[p]));
            }
            queryString = queryString.join("&");
            // End Serialize

            axios
                .get('/api/internal/calculate-sell-car?' + queryString)
                .then((r) => {
                    this.showResult = true;
                    this.sellCalculation = r.data;
                    this.sellFormLoading = false;
                })
                .catch(() => {
                    alert('Произошла ошибка. Пожалуйста, свяжитесь с менеджером для расчёта');
                });
        }
    },
    components: {
        'toggler': Toggler,
        'buy-screen': BuyScreen,
        'sell-screen': SellScreen,
        'sell-offer-screen': SellOfferScreen,
        'sell-screen-full': SellScreenFull,
    },
};
</script>
