import * as Vue from 'vue';

import HomeSearch from './components/HomeSearch/home-search.vue';

const app = Vue.createApp({
    components: {
        'home-search': HomeSearch,
    }
});

app.mixin({
    data() {
        return {
            region: window.region,
            routes: window.routes,
        };
    }
});
app.mount('#home-search');
