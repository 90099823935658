<template>
    <div :class="['direction-toggler', 'direction-toggler-toggle', {'toggle': this.mode == 'sell', 'w-100': windowWidth < 992}]">
        <button type="button" class="btn z-2 r20 w-100" data-item="buy" @click="$emit('toggle', 'buy')">Купить</button>
        <button type="button" class="btn z-2 r20 w-100" data-item="sell" @click="$emit('toggle', 'sell')">Продать</button>
    </div>
</template>

<script>
export default {
    props: {
        mode: {
            type: String,
            default: 'buy',
            validator(value) {
                return ['buy', 'sell'].includes(value)
            }
        }
    },
    data() {
        return {
            windowWidth: window.innerWidth,
        }
    }
};
</script>
