<template>
    <div class="tag tag-opacity" :class="{ 'tag-selected': checked, 'tag-closable': checked }" @click.stop="!checked && $emit('check')">
        <slot></slot>
        <button v-if="checked" type="button" class="btn tag-close" @click.stop="$emit('uncheck')"></button>
    </div>
</template>

<script>
export default {
    emits: ['check', 'uncheck'],
    props: {
        checked: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
