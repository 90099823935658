<template>
    <div class="row mb-2 mb-md-4 g-2 g-md-3">
        <div class="col-6 col-md">
            <select name="brand" v-model.lazy="form.brand" class="select select-lg w-100 select2">
                <option value="" disabled>Марка</option>
                <option v-for="(name, id) in constants.brands" :key="id" :value="id">{{ name }}</option>
            </select>
        </div>
        <div class="col-6 col-md">
            <select name="model" v-model.lazy="form.model" class="select select-lg w-100">
                <option value="" disabled>Модель</option>
                <option v-for="(name, id) in constants.models" :key="id" :value="id">{{ name }}</option>
            </select>
        </div>
        <div class="col-6 col-md">
            <div class="input-group input-group-lg">
                <input type="number" id="form-price_from" name="price_from" v-model.lazy.number="form.price_from" class="input input-lg w-100" placeholder="Цена от" @keypress="NumbersOnly($event)">
                <label for="form-price_from" class="input-addon gray">₽</label>
            </div>
        </div>
        <div class="col-6 col-md">
            <div class="input-group input-group-lg">
                <input type="number" id="form-price_to" name="price_to" v-model.lazy.number="form.price_to" class="input input-lg w-100" placeholder="Цена до" @keypress="NumbersOnly($event)">
                <label for="form-price_to" class="input-addon gray">₽</label>
            </div>
        </div>
        <div class="col-12 col-md">
            <select name="transmission" v-model.lazy="form.transmission" class="select select-lg w-100">
                <option value="" disabled>Коробка</option>
                <option v-for="(name, id) in constants.transmissions" :key="id" :value="id">{{ name }}</option>
            </select>
        </div>
    </div>
    <div v-if="filters.advancedSearch" class="row mb-2 mb-md-4 g-2 g-md-3">
        <div class="col-12 col-md">
            <input type="number" name="year_from" v-model.lazy.number="form.year_from" class="input input-lg w-100" placeholder="Год, от" @keypress="NumbersOnly($event)">
        </div>
        <div class="col-12 col-md">
            <input type="number" name="year_to" v-model.lazy.number="form.year_to" class="input input-lg w-100" placeholder="Год, до" @keypress="NumbersOnly($event)">
        </div>
        <div class="col-12 col-md">
            <input type="number" name="mileage_from" v-model.lazy.number="form.mileage_from" class="input input-lg w-100" placeholder="Пробег, от" @keypress="NumbersOnly($event)">
        </div>
        <div class="col-12 col-md">
            <input type="number" name="mileage_to" v-model.lazy.number="form.mileage_to" class="input input-lg w-100" placeholder="Пробег, до" @keypress="NumbersOnly($event)">
        </div>
        <div class="col-12 col-md">
            <select name="body_type" v-model.lazy="form.body_type" class="select select-lg w-100">
                <option value="" disabled>Тип кузова</option>
                <option v-for="(name, id) in constants.body_types" :key="id" :value="id">{{ name }}</option>
            </select>
        </div>
    </div>
    <div v-if="filters.advancedSearch" class="row mb-2 mb-md-4 g-2 g-md-3">
        <div class="col-12 col-md">
            <select name="drive" v-model.lazy="form.drive" class="select select-lg w-100">
                <option value="" disabled>Привод</option>
                <option v-for="(name, id) in constants.drive" :key="id" :value="id">{{ name }}</option>
            </select>
        </div>
        <div class="col-12 col-md">
            <select name="engine" v-model.lazy="form.engine" class="select select-lg w-100">
                <option value="" disabled>Двигатель</option>
                <option v-for="(name, id) in constants.engine" :key="id" :value="id">{{ name }}</option>
            </select>
        </div>
        <div class="col-12 col-md">
            <input type="number" name="engine_capacity_from" v-model.lazy.number="form.engine_capacity_from" class="input input-lg w-100" placeholder="Объём, от" @keypress="NumbersOnly($event)">
        </div>
        <div class="col-12 col-md">
            <input type="number" name="engine_capacity_to" v-model.lazy.number="form.engine_capacity_to" class="input input-lg w-100" placeholder="Объём, до" @keypress="NumbersOnly($event)">
        </div>
        <div class="col-12 col-md">
            <select name="color" v-model.lazy="form.color" class="select select-lg w-100">
                <option value="" disabled>Цвет</option>
                <option v-for="(name, id) in constants.colors" :key="id" :value="id">{{ name }}</option>
            </select>
        </div>
    </div>
    <div v-if="filters.advancedSearch" class="row mb-md-4 g-2 g-md-3">
        <div class="col-12 col-md">
            <input type="number" name="power_from" v-model.lazy.number="form.power_from" class="input input-lg w-100" placeholder="Мощность, от" @keypress="NumbersOnly($event)">
        </div>
        <div class="col-12 col-md">
            <input type="number" name="power_to" v-model.lazy.number="form.power_to" class="input input-lg w-100" placeholder="Мощность, до" @keypress="NumbersOnly($event)">
        </div>
        <div class="d-none d-md-block col-md"></div>
        <div class="d-none d-md-block col-md"></div>
        <div class="d-none d-md-block col-md"></div>
    </div>
    <div class="my-3">
        <checkbox :checked="form.owner" @check="form.owner = true" @uncheck="form.owner = false">
            Продает собственник
        </checkbox>
        <checkbox :checked="form.new" @check="form.new = true" @uncheck="form.new = false">
            Новые поступления
        </checkbox>
        <checkbox :checked="form.discount" @check="form.discount = 'max'" @uncheck="form.discount = null">
            Выгодная цена
        </checkbox>
    </div>
    <hr class="d-none d-md-block mb-3">
    <div class="row">
        <div class="col-12 col-md-4 col-lg vertical-center mb-3">
            <div class="form-check form-switch mb-3 mb-lg-0">
                <input class="form-check-input" type="checkbox" role="switch" id="heroSearchAdvanced" v-model.lazy="filters.advancedSearch">
                <label for="heroSearchAdvanced" class="fw-normal">Расширенный поиск</label>
            </div>
        </div>
        <div class="col-12 col-md-8 col-lg">
            <div class="btn-hero-container">
                <button class="btn btn-primary btn-lg mb-3 mb-md-0 btn-hero-footer position-relative overflow-hidden" @click="submit">
                    <spinner v-show="countInUpdate" class="position-absolute p-2 w-100 h-100" style="top:0;left:0;right:0;bottom:0;background:inherit" stroke-width="12" />
                    <span v-html="okButtonText"></span>
                </button>
                <button class="btn btn-opacity btn-lg mb-3 mb-md-0 btn-hero-footer" @click="reset">Очистить поиск</button>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import Checkbox from './checkbox';
import Spinner from '../Spinner';

export default {
    components: {
        'checkbox': Checkbox,
        'spinner': Spinner,
    },
    data() {
        return {
            countInUpdate: false,
            filters: {
                advancedSearch: false,
            },
            form: {
                brand: '',
                model: '',
                price_from: '',
                price_to: '',
                transmission: '',
                year_from: '',
                year_to: '',
                mileage_from: '',
                mileage_to: '',
                body_type: '',
                drive: '',
                engine: '',
                engine_capacity_from: '',
                engine_capacity_to: '',
                color: '',
                power_from: '',
                power_to: '',
                owner: true,
                new: false,
                discount: null,
                advancedSearch: false,
                owner: true,
                new: false,
            },
            constants: {
                brands: {
                    1: 'BMW',
                    2: 'Mercedes',
                    3: 'Lada (ВАЗ)',
                    4: 'Volkswagen',
                },
                models: {
                    1: 'M3',
                    2: '600',
                    3: 'Priora',
                    4: 'Golf',
                },
                colors: {
                    1: 'Белый',
                    2: 'Чёрный',
                    3: 'Зелёный',
                    4: 'Красный',
                    5: 'Синий',
                },
                body_types: {
                    1: 'Седан',
                    2: 'Хэтчбек',
                    3: 'Универсал',
                },
                drive: {
                    1: 'Передний',
                    2: 'Задний',
                    3: 'Полный',
                },
                engine: {
                    1: 'Какой-то',
                    2: 'Какой-то ещё',
                },
                transmissions: {
                    manual: 'Механика',
                    automatic: 'Автомат',
                    robotized: 'Робот',
                    variator: 'Вариатор'
                },
            },
            resultCount: null,
        };
    },
    computed: {
        okButtonText() {
            let text = 'Показать'

            if (this.resultCount != null)
                text += ' <span class="anim-new rounded-1 px-1">' + this.resultCount.toLocaleString() + ' авто</span>';

            return text;
        }
    },
    methods: {
        resetForm() {
            for (const k in this.form) this.form[k] = '';
        },
        NumbersOnly(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        submit() {
            const t = this;
            t.$emit('submit', t.form);
            t.$emit('loading');
            let form = Object.fromEntries(Object.entries(this.form).filter(([_, v]) => !!v));
            let query = [];
            for (let p in this.form) if (form.hasOwnProperty(p)) {
                query.push(encodeURIComponent(p) + "=" + encodeURIComponent(form[p]));
            }
            window.location.href = '/catalog?' + query.join("&");
        },
        updateCount() {
            const t = this;
            let time = +(new Date());
            t.countInUpdate = time;
            let params = {
                select: ['count'],
                filters: []
            };

            params.filters = Object.fromEntries(Object.entries(this.form).filter(([_, v]) =>  v != null && v != ''));

            axios
                .get('/api/internal/catalog?' + this.toQueryString(params))
                .then((r) => { if (t.countInUpdate == time) t.resultCount = r.data.count; })
                .catch()
                .then(() => { if (t.countInUpdate == time) t.countInUpdate = false; });
        },
        reset() {
            this.$emit('reset', this.form);
            for (const k in this.form) this.form[k] = '';
            this.resultCount = null;
            this.form.owner = true;
            this.form.new = false;
            this.form.discount = null;
        },
        toQueryString(obj, prefix) {
            var str = [], k, v;
            for(var p in obj) {
                if (!obj.hasOwnProperty(p)) {continue;}
                if (~p.indexOf('[')) {
                    k = prefix ? prefix + "[" + p.substring(0, p.indexOf('[')) + "]" + p.substring(p.indexOf('[')) : p;
                } else {
                    k = prefix ? prefix + "[" + p + "]" : p;
                }
                v = obj[p];
                str.push(typeof v == "object" ?
                    this.toQueryString(v, k) :
                    encodeURIComponent(k) + "=" + encodeURIComponent(v));
            }
            return str.join("&");
        }
    },
    watch: {
        form: {
            deep: true,
            handler(a, b) {
                this.updateCount();
            }
        }
    },
    mounted() {
        this.updateCount();
    }
};
</script>
