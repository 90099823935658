<script>
export default {
    props: {
        strokeWidth: {
            default: 6
        }
    }
};
</script>

<template>
    <svg class="spinner" width="30px" height="30px" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <rect class="spinner__rect" x="0" y="0" width="100" height="100" fill="none"></rect>
        <circle class="spinner__circle" cx="50" cy="50" r="40" stroke="currentColor" fill="none" :stroke-width="strokeWidth" stroke-linecap="round" stroke-alignment="inner"></circle>
	</svg>
</template>
