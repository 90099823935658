
<script>
import Spinner from '../Spinner.vue';

export default {
    emits: ['submit', 'forget'],
    props: {
        loading: {
            type: Boolean,
            default: false,
        }
    },
    components: {
        'spinner': Spinner,
    },
    data() {
        return {
            constants: {
                ptsTypes: {
                    original: 'Оригинал',
                    duplicate: 'Дубликат',
                    digital: 'Электронный',
                },
                cities: {
                    1: 'Батайск',
                    2: 'Новочеркасск',
                },
            },
            form: {
                vin: '',
                mileage: '',
                city: '',
                owners: '',
                pts: '',
            }
        };
    },
    methods: {
        submit() {
            if (this.$refs.mainForm.checkValidity()) {
                this.$emit('submit', this.form);
            } else {
                this.$refs.mainForm.reportValidity();
            }
        },
        NumbersOnly(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
    }
};
</script>

<template>
    <form ref="mainForm" onsubmit="return false">
        <div class="row mb-4 flex-md-nowrap g-2 g-md-3">
            <div class="col-12 col-lg">
                <input type="text" name="vin" class="input input-lg w-100 mb-1" v-model="form.vin" placeholder="Введите VIN номер" required>
            </div>
            <div class="col-6 col-lg">
                <div class="input-group input-group-lg mb-1">
                    <input type="number" id="form-mileage" name="mileage" class="input w-100" v-model="form.mileage" placeholder="Пробег" @keypress="NumbersOnly($event)" required>
                    <label for="form-mileage" class="input-addon">км</label>
                </div>
            </div>
            <div class="col-6 col-lg">
                <select name="city" v-model="form.city" class="select select-lg w-100 mb-1 select-lg w-100" required>
                    <option value="" disabled>Город</option>
                    <option v-for="(name, id) in constants.cities" :key="id" :value="id">{{ name }}</option>
                </select>
            </div>
            <div class="col-12 col-lg">
                <input type="number" name="owners" class="input input-lg w-100 mb-1" v-model="form.owners" placeholder="Владельцев по ПТС" @keypress="NumbersOnly($event)" required>
            </div>
            <div class="col-12 col-lg">
                <select name="pts" v-model="form.pts" class="select select-lg w-100 mb-1 select-lg w-100" required>
                    <option value="" disabled>ПТС</option>
                    <option v-for="(name, value) in constants.ptsTypes" :key="name" :value="value">{{ name }}</option>
                </select>
            </div>
        </div>

        <div class="text-muted mb-3">
            Указанная рассчетная стоимость является приблизительной и действует при сдаче автомобиля в trade-in
        </div>

        <hr class="mb-3">

        <div class="d-flex flex-column flex-md-row-reverse align-items-stretch">
            <button type="submit" class="btn btn-lg btn-primary d-block w-md-auto mb-2 px-4 position-relative overflow-hidden" @click="submit()" :disabled="loading">
                Получить предложение
                <spinner v-if="loading" class="position-absolute p-2 w-100 h-100" style="top:0;left:0;right:0;bottom:0;background:inherit" stroke-width="12" />
            </button>
            <button type="button" class="btn btn-lg btn-opacity d-block w-md-auto mb-2 px-4 me-md-3" @click="$emit('forget')" :disabled="loading">Я не помню VIN</button>
        </div>
    </form>
</template>
