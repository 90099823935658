<script>
export default {
    emits: ['submit', 'cancel'],
    props: {
        calculation: {
            type: Object,
            required: true,
        }
    }
};
</script>

<template>
    <div class="row">
        <div class="col-md-8 mb-4">
            <div class="bg-opacity text-white rounded px-4 py-3">
                <h3 class="subtitle-3">Ваш автомобиль</h3>
                <div class="d-flex flex-column flex-md-row justify-content-md-start align-items-stretch align-items-md-start text-white-50">
                    <table class="me-0 me-md-5">
                        <tbody>
                            <tr>
                                <td class="col-6 col-md-auto pe-md-4 py-1">Марка</td>
                                <td class="py-1">{{ calculation.brand }}</td>
                            </tr>

                            <tr>
                                <td class="col-6 col-md-auto pe-md-4 py-1">Модель</td>
                                <td class="py-1">{{ calculation.model }}</td>
                            </tr>

                            <tr>
                                <td class="col-6 col-md-auto pe-md-4 py-1">Год выпуска</td>
                                <td class="py-1">{{ calculation.year }}</td>
                            </tr>

                            <tr>
                                <td class="col-6 col-md-auto pe-md-4 py-1">Поколение</td>
                                <td class="py-1">{{ calculation.generation }}</td>
                            </tr>

                            <tr>
                                <td class="col-6 col-md-auto pe-md-4 py-1">Двигатель</td>
                                <td class="py-1">{{ calculation.engine }}</td>
                            </tr>
                        </tbody>
                    </table>

                    <table>
                        <tbody>
                            <tr>
                                <td class="col-6 col-md-auto pe-md-4 py-1">Модификация</td>
                                <td class="py-1">{{ calculation.modification }}</td>
                            </tr>
                            <tr>
                                <td class="col-6 col-md-auto pe-md-4 py-1">Тип кузова</td>
                                <td class="py-1">{{ calculation.body_type }}</td>
                            </tr>
                            <tr>
                                <td class="col-6 col-md-auto pe-md-4 py-1">Привод</td>
                                <td class="py-1">{{ calculation.drive }}</td>
                            </tr>
                            <tr>
                                <td class="col-6 col-md-auto pe-md-4 py-1">КПП</td>
                                <td class="py-1">{{ calculation.kpp }}</td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
        <div class="col-md-4 mb-4">
            <div class="bg-white text-dark rounded px-4 py-3">
                <div class="mb-3">
                    <div class="small opacity-50">При сдаче в трейд ин</div>
                    <div class="subtitle-1 font-weight-bold">{{ calculation.price.tradein.toLocaleString() }} ₽</div>
                </div>
                <div class="mb-3">
                    <div class="small opacity-50">При самостоятельной продаже</div>
                    <div class="subtitle-1 font-weight-bold">{{ calculation.price.selfsell.toLocaleString() }} ₽</div>
                </div>
                <div>
                    <div class="small opacity-50">Ваш номер расчета</div>
                    <button class="btn p-0 align-text-top" onclick="copyThisElementContent(this)">
                        <span class="subtitle-1 text-dark font-weight-bold">{{ calculation.calculation_id }}</span>
                        <svg width="16" height="16" viewBox="0 0 24 24" class="align-text-top ms-2" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path id="icon" fill-rule="evenodd" clip-rule="evenodd" d="M10.5 1.5H19.5C21.2284 1.5 22.5 2.77157 22.5 4.5V13.5C22.5 15.2284 21.2284 16.5 19.5 16.5H16.5V19.5C16.5 21.2284 15.2284 22.5 13.5 22.5H4.5C2.77157 22.5 1.5 21.2284 1.5 19.5V10.5C1.5 8.77157 2.77157 7.5 4.5 7.5H7.5V4.5C7.5 2.77157 8.77157 1.5 10.5 1.5ZM7.5 10.5H4.5V19.5H13.5V16.5H10.5C8.77157 16.5 7.5 15.2284 7.5 13.5V10.5ZM10.5 4.5V13.5H19.5V4.5H10.5Z" fill="black"></path>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="d-flex flex-column flex-md-row-reverse align-items-stretch">
        <button type="button" class="btn btn-lg btn-primary d-block w-md-auto mb-2 px-4" @click="$emit('submit')">
            Оставить заявку дилеру
        </button>
        <button type="button" class="btn btn-lg btn-opacity d-block w-md-auto mb-2 px-4 me-md-3" @click="$emit('cancel')">Вернуться к подбору</button>
    </div>
</template>
