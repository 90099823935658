<template>
    <div class="subtitle-3 mb-4">Данные вашего авто</div>

    <form ref="mainForm" onsubmit="return false">
        <div class="row mb-4">
            <div class="col-6 col-md-4 col-lg-3 mb-4">
                <select name="brand" v-model="form.brand" class="select select-lg w-100" required>
                    <option value="" disabled>Марка</option>
                    <option v-for="(name, id) in constants.brands" :key="id" :value="id">{{ name }}</option>
                </select>
            </div>
            <div class="col-6 col-md-4 col-lg-3 mb-4">
                <select name="model" v-model="form.model" class="select select-lg w-100" required>
                    <option value="" disabled>Модель</option>
                    <option v-for="(name, id) in constants.models" :key="id" :value="id">{{ name }}</option>
                </select>
            </div>
            <div class="col-6 col-md-4 col-lg-3 mb-4">
                <input type="number" name="year" v-model="form.year" class="input input-lg w-100" placeholder="Год выпуска" required>
            </div>
            <div class="col-6 col-md-4 col-lg-3 mb-4">
                <select name="generation" v-model="form.generation" class="select select-lg w-100" required>
                    <option value="" disabled>Поколение</option>
                    <option v-for="(name, id) in constants.generations" :key="id" :value="id">{{ name }}</option>
                </select>
            </div>
            <div class="col-6 col-md-4 col-lg-3 mb-4">
                <select name="body" v-model="form.body" class="select select-lg w-100" required>
                    <option value="" disabled>Тип кузова</option>
                    <option v-for="(name, id) in constants.body_types" :key="id" :value="id">{{ name }}</option>
                </select>
            </div>
            <div class="col-6 col-md-4 col-lg-3 mb-4">
                <select name="modification" v-model="form.modification" class="select select-lg w-100" required>
                    <option value="" disabled>Модификация</option>
                    <option v-for="(name, id) in constants.modifications" :key="id" :value="id">{{ name }}</option>
                </select>
            </div>
            <div class="col-6 col-md-4 col-lg-3 mb-4">
                <select name="engine" v-model="form.engine" class="select select-lg w-100" required>
                    <option value="" disabled>Двигатель</option>
                    <option v-for="(name, id) in constants.engines" :key="id" :value="id">{{ name }}</option>
                </select>
            </div>
            <div class="col-6 col-md-4 col-lg-3 mb-4">
                <select name="drive" v-model="form.drive" class="select select-lg w-100" required>
                    <option value="" disabled>Привод</option>
                    <option v-for="(name, id) in constants.drives" :key="id" :value="id">{{ name }}</option>
                </select>
            </div>
            <div class="col-6 col-md-4 col-lg-3 mb-4">
                <select name="kpp" v-model="form.kpp" class="select select-lg w-100" required>
                    <option value="" disabled>КПП</option>
                    <option v-for="(name, id) in constants.kpp" :key="id" :value="id">{{ name }}</option>
                </select>
            </div>
            <div class="col-6 col-md-4 col-lg-3 mb-4">
                <input type="number" name="mileage" v-model="form.mileage" class="input input-lg w-100" placeholder="Пробег">
            </div>
        </div>

        <div class="subtitle-3 mb-4">Выбор салона</div>

        <div class="row">
            <div class="col-12 col-lg-6 mb-2">
                <select name="city" v-model="form.city" class="select select-lg w-100" required>
                    <option value="" disabled>Город</option>
                    <option v-for="(name, id) in constants.cities" :key="id" :value="id">{{ name }}</option>
                </select>
            </div>
        </div>

        <hr>

        <div class="subtitle-3 mb-2">Дополнительная информация</div>
        <p>Если вы заполните эти поля, повысите точность расчета</p>

        <div class="row">
            <div class="col-6 col-lg-3 mb-4">
                <select name="equipment" v-model="form.equipment" class="select select-lg w-100" required>
                    <option value="" disabled>Комплектация</option>
                    <option v-for="(name, id) in constants.equipment" :key="id" :value="id">{{ name }}</option>
                </select>
            </div>
            <div class="col-6 col-lg-3 mb-4">
                <select name="pts" v-model="form.pts" class="select select-lg w-100" required>
                    <option value="" disabled>ПТС</option>
                    <option v-for="(name, id) in constants.pts" :key="id" :value="id">{{ name }}</option>
                </select>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg-3 mb-4">
                <input type="number" name="owners" v-model="form.owners" class="input input-lg w-100" placeholder="Владельцев по ПТС">
            </div>
        </div>
        <div class="row">
            <label for="form_right_wheel" class="d-flex align-items-center mb-3">
                <input type="checkbox" id="form_right_wheel" name="right_wheel" v-model="form.right_wheel" class="form-check-input form-check-input-sm me-2">
                Правый руль
            </label>
        </div>

        <p class="text-muted">Указанная рассчетная стоимость является приблизительной и действует при сдаче автомобиля в trade-in</p>

        <hr>

        <div class="d-flex flex-column align-items-stretch flex-md-row-reverse">
            <button @click="submit()" type="button" class="btn btn-lg btn-primary d-block mb-2 ms-md-3 px-3 position-relative" :disabled="loading">
                Оставить заявку дилеру
                <spinner v-if="loading" class="position-absolute p-2 w-100 h-100" style="top:0;left:0;right:0;bottom:0;background:inherit" stroke-width="12" />
            </button>
            <button @click="$emit('cancel')" type="button" class="btn btn-lg btn-opacity d-block mb-2 ms-md-3 px-5" :disabled="loading">
                Назад
            </button>
            <div class="d-flex">
                <button class="btn btn-lg btn-opacity d-block mb-2 w-100">
                    <svg class="align-text-top" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path id="icon" fill-rule="evenodd" clip-rule="evenodd" d="M15.4142 1H5C3.89543 1 3 1.89543 3 3V21C3 22.1046 3.89543 23 5 23H19C20.1046 23 21 22.1046 21 21V6.58579L15.4142 1ZM5 3H13V7C13 8.10457 13.8954 9 15 9H19V21H5V3ZM15 7V3.41421L18.5858 7H15ZM11 10V14.5858L9.70711 13.2929L8.29289 14.7071L12 18.4142L15.7071 14.7071L14.2929 13.2929L13 14.5858V10H11Z" fill="white"/>
                    </svg>
                </button>
                <button class="btn btn-lg btn-opacity d-block mb-2 w-100 ms-2 ms-md-3">
                    <svg class="align-text-top" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path id="icon" fill-rule="evenodd" clip-rule="evenodd" d="M3 3H21C22.1046 3 23 3.89543 23 5V19C23 20.1046 22.1046 21 21 21H3C1.89543 21 1 20.1046 1 19V5C1 3.89543 1.89543 3 3 3ZM3 9.61811V19H21V9.61853L12 14.1185L3 9.61811ZM3 7.38199L12 11.8825L21 7.38247V5H3V7.38199Z" fill="white"/>
                    </svg>
                </button>
                <button class="btn btn-lg btn-opacity d-block mb-2 w-100 ms-2 ms-md-3">
                    <svg class="align-text-top" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path id="icon" fill-rule="evenodd" clip-rule="evenodd" d="M2.74014 2.25208L4.10515 11.8071L4.8769 12L4.10515 12.193L2.74014 21.748L22.2361 12L2.74014 2.25208ZM5.89486 10.193L5.25987 5.748L17.7639 12L5.25987 18.2521L5.89486 13.8071L13.1231 12L5.89486 10.193Z" fill="white"/>
                    </svg>
                </button>
            </div>
        </div>
    </form>
</template>

<script>
import Spinner from '../Spinner.vue';

export default {
    emits: [
        'loading', 'load', 'submit', 'cancel'
    ],
    props: {
        loading: {
            type: Boolean,
            default: false,
        }
    },
    components: {
        'spinner': Spinner,
    },
    data() {
        return {
            constants: {
                brands: {
                    1: 'BMW',
                    2: 'Audi',
                    3: 'Lada (ВАЗ)',
                },
                models: {
                    1: 'M3',
                    2: 'Q5',
                    3: 'Priora',
                },
                generations: {},
                body_types: {},
                modifications: {},
                engines: {},
                drives: {},
                kpp: {
                    manual: 'Механика',
                    autimatic: 'Автомат',
                    robot: 'Робот'
                },
                cities: {
                    1: 'Батайск',
                    2: 'Новочеркасск'
                },
                equipments: {},
                pts: {
                    original: 'Оригинальный',
                    duplicate: 'Дубликат',
                    digital: 'Электронный',
                },
            },
            form: {
                brand: '',
                model: '',
                year: '',
                generation: '',
                body: '',
                modification: '',
                engine: '',
                drive: '',
                kpp: '',
                mileage: '',
                city: '',
                equipment: '',
                pts: '',
                owners: '',
                right_wheel: '',
            }
        };
    },
    methods: {
        submit() {
            if (this.$refs.mainForm.checkValidity()) {
                this.$emit('submit', this.form);
            } else {
                this.$refs.mainForm.reportValidity();
            }
        },
    }
};
</script>
